<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.avatar"
            :text="avatarText(userData.fullName)"
            :variant="`light-${resolveUserRoleVariant(userData.roles)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.fullName }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                v-if="$can('update', 'users') && [1,2].includes(+userRol.id)"
                :to="{ name: 'admin-users-edit', params: { id: userData.id } }"
                variant="primary"
              >
                Edit
              </b-button>
              <b-button 
                v-if="$can('destroy', 'users') && [1,2].includes(+userRol.id)" 
                @click="deleteUser(userData.id)" 
                variant="outline-danger" 
                class="ml-1">
                Delete
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <i class="fas fa-dollar-sign"></i>
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">23.3k</h5>
              <small>Monthly Sales</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <i class="far fa-chart-bar"></i>
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">$99.87k</h5>
              <small>Annual Profit</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-2">
              <i class="far fa-user mr-2"></i>
              <span class="font-weight-bold">Fullname</span>
            </th>
            <td class="pb-2">{{ userData.name }} {{ userData.lastname }}</td>
          </tr>
          <tr>
            <th class="pb-2">
              <i class="fas fa-check mr-2"></i>
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-2 text-capitalize">
              {{ userData.status }}
            </td>
          </tr>
          <tr>
            <th class="pb-2">
              <i class="fas fa-sitemap mr-2"></i>
              <span class="font-weight-bold">Role</span>
            </th>
            <td class="pb-2 text-capitalize">
              {{ userData.roles.length > 0 ? userData.roles[0].name : 'No Role' }}
            </td>
          </tr>
          <tr v-if="proDetail">
            <th class="pb-2">
              <i class="fas fa-city mr-2"></i>
              <span class="font-weight-bold">City</span>
            </th>
            <td class="pb-2">
              {{ userData.cities.name }}, {{ userData.cities.states.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-2">
              <i class="fas fa-directions mr-2"></i>
              <span class="font-weight-bold">Address</span>
            </th>
            <td class="pb-2">
              {{ userData.address }}
            </td>
          </tr>
          <tr>
            <th>
              <i class="fas fa-phone mr-2"></i>
              <span class="font-weight-bold">Phone</span>
            </th>
            <td v-if="userData.phone">
              {{ formatPhone(userData.phone) }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>

    <slot></slot>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol } from "bootstrap-vue";
import { avatarText } from "@/core/utils/filter";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    proDetail: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const deleteUser = (id) =>{
      // console.log(id);
    }
    const resolveUserRoleVariant = (roles) => {
      if (roles[0].name === "FRONTDESK") return "warning";
      if (roles[0].name === "ADMIN") return "success";
      if (roles[0].name === "MEDICAL-ASSISTANT") return "info";
      if (roles[0].name === "PMA") return "info";
      if (roles[0].name === "SUPER-ADMIN") return "danger";
      return "primary";
    }
    const formatPhone = (phone) =>  {
      return phone
        .toString()
        .replace(/\D+/g, "")
        .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }
    return {
      avatarText,
      resolveUserRoleVariant,
      deleteUser,
      formatPhone,
    };
  },
  data() {
    return {
      userRol: JSON.parse(localStorage.getItem("userData")).roles[0]
    };
  },
};
</script>

<style>
</style>
