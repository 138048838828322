<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === null">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'admin-users' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>
    <b-row>
      <!-- {{ $t("Search") }} -->
      <b-col cols="12" md="12">
        <div class="d-flex align-items-center justify-content-end">
          <GoBack class="mb-2" />
        </div>
      </b-col>
    </b-row>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="9" lg="8" md="7">
          <user-view-user-info-card :proDetail="true" :user-data="userData" />
        </b-col>
        <b-col cols="12" md="5" xl="3" lg="4">
          <user-view-user-plan-card :facilities="userData.facilities" :user-data="userData" />
        </b-col>
      </b-row>

      <!-- <b-row>
        <b-col cols="12" lg="6">
          <user-view-user-timeline-card />
        </b-col>
        <b-col cols="12" lg="6">
          <user-view-user-permissions-card />
        </b-col>
      </b-row>

      <invoice-list /> -->
    </template>
  </div>
</template>

<script>
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";
/* import InvoiceList from "@/views/apps/invoice/invoice-list/InvoiceList.vue"; */

import UserViewUserInfoCard from "./UserViewUserInfoCard.vue";
import UserViewUserPlanCard from "./UserViewUserPlanCard.vue";
/* import UserViewUserTimelineCard from "./UserViewUserTimelineCard.vue";
import UserViewUserPermissionsCard from "./UserViewUserPermissionsCard.vue"; */
import GoBack from "@/components/ButtonBack.vue";

import axiosU from "@/core/services/api/admin/user";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    GoBack,
  },
  setup() {
    const userData = ref(null);
    axiosU
      .userDetail(router.currentRoute.params.id)
      .then((response) => {
        userData.value = response && response.registro;
      })
      .catch((error) => {
        userData.value = null;
      });


    return {
      userData,
    };
  },
};
</script>

<style>
</style>
